

function getBaseDomain(){
    //return "https://api.professorstoday.ru";
    
    if((window.location.hostname).indexOf("localhost") + 1){
        return "http://localhost:8092";
        //return "https://api.professorstoday.ru";
    } else {
        return "https://api.professorstoday.ru";
    }
    
}

const constant = {
    "baseDomain" : getBaseDomain(),
}

export default constant;