import { useEffect, useRef, useState } from "react"
import UserMiddleware from "../utils/UserMiddleware";
import StatementService from "../services/StatementService";
import { SystemLoadingPage } from "../components/SystemLoadingPage";
import { SystemErrorPage } from "../components/SystemErrorPage";
import { Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import constant from "../utils/GlobalValues";

export function ContestStatementPage(props)
{
    //const { contest_id } = useParams();
    //console.log("contest_id: " + contest_id);
    //const contest_id = 1;
    const {contest_id} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isSubLoading, setIsSubLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [isError, setIsError] = useState();
    const [statementFile, setStatementFile] = useState();
    const [photoFile, setPhotoFile] = useState();
    const [userStatementViewModel, setUserStatementViewModel] = useState(null);
    const [nominationMicroViewModels, setNominationMicroViewModels] = useState([]);
    const [warning, setWarning] = useState("Результат запроса");
    const [statementPage, setStatementPage] = useState(1);

    const inputStatement = useRef();
    const inputPhotoFile = useRef();

    const [confirmingOfParticipationInKinoIntellectFile, setConfirmingOfParticipationInKinoIntellectFile] = useState();
    const inputConfirmingOfParticipationInKinoIntellectFile = useRef();

    //const [applicationsAndCharacteristicFiles, setApplicationsAndCharacteristicFiles] = useState();
    const [applicationsAndCharacteristicFile, setApplicationsAndCharacteristicFile] = useState();
    const inputApplicationsAndCharacteristicFile = useRef();

    //const [otherFiles, setOtherFiles] = useState();
    const [otherFile, setOtherFile] = useState();
    const inputOtherFile = useRef();

    const [processDataApplicationFile, setProcessDataApplicationFile] = useState(null);
    const navigate = useNavigate();

    let statementsHeaders = [
        "Заявление на участие в конкурсе",
        "Степени и звания",
        "Стаж и дополнительное образование",
        "Общественная и научная активность",
        "Преподавательская деятельность",
        "Прочие данные"
    ];

    const statementService = new StatementService();
    const userMiddleware = new UserMiddleware();
    const jwt = userMiddleware.getJWTFromCookie();

    useEffect(() => {
        setWarning("");
    }, [userStatementViewModel, statementPage]);

    
    const formListener = (e) => {
        let copiedUserStatementViewModel = {...userStatementViewModel};
        copiedUserStatementViewModel[e.target.name] = e.target.value;
        setUserStatementViewModel(() => ({
            ...copiedUserStatementViewModel
        }));
    }

    const handleStatementFile = (e) => {
        if (e.target.files) {
            setStatementFile(e.target.files[0]);
        }
    };
    const handlePhotoFile = (e) => {
        if (e.target.files) {
            setPhotoFile(e.target.files[0]);
        }
    };
    const handleConfirmingOfParticipationInKinoIntellectFile = (e) => {
        if (e.target.files) {
            setConfirmingOfParticipationInKinoIntellectFile(e.target.files[0]);
        }
    };
    /*
    const handleApplicationsAndCharacteristicFilesChange = (e) => {
        if (e.target.files) {
            setApplicationsAndCharacteristicFiles(e.target.files);
        }
    };
    */
    const handleApplicationsAndCharacteristicFileChange = (e) => {
        if (e.target.files) {
            setApplicationsAndCharacteristicFile(e.target.files[0]);
        }
    };
    /*
    const handleOtherFilesChange = (e) => {
        if (e.target.files) {
            setOtherFiles(e.target.files);
        }
    };
    */
    const handleOtherFileChange = (e) => {
        if (e.target.files) {
            setOtherFile(e.target.files[0]);
        }
    };
    const handleProcessDataApplicationFile = (e) => {
        if (e.target.files) {
            setProcessDataApplicationFile(e.target.files[0]);
        }
    };

    const getCallback = async() => {
        await statementService.get(
            jwt,
            setIsLoading,
            setIsError,
            contest_id,
            setNominationMicroViewModels,
            setUserStatementViewModel
        );
    }

    function changePage(value){
        if(value)
        {
            if(statementPage >= 6)
            {
                return;
            }
            setStatementPage(statementPage + 1);
        } else 
        {
            if(statementPage === 1)
            {
                return;
            }
            setStatementPage(statementPage - 1);
        }
    }

    useEffect(() => {

        const userMiddleware = new UserMiddleware();
        const jwt = userMiddleware.getJWTFromCookie();

        async function get(){
            const statementService = new StatementService();
            await statementService.get(
                jwt,
                setIsLoading,
                setIsError,
                contest_id,
                setNominationMicroViewModels,
                setUserStatementViewModel
            );
        }
        get();

        return () => {
            //console.log("useEffect empty");
        }
    }, [])

    if(isLoading)
    {
        return (
            <SystemLoadingPage />
        )
    }

    if(isError)
    {
        return (
            <SystemErrorPage tryAgain={async() => await statementService.get(
                    jwt,
                    setIsLoading,
                    setIsError,
                    contest_id,
                    setNominationMicroViewModels,
                    setUserStatementViewModel
                )
            } />
        )
    }

    let nominationMicroOptions = nominationMicroViewModels.map((nominationMicroViewModel, index) => {
        return <option key={index} value={nominationMicroViewModel.id}>{nominationMicroViewModel.name}</option>
    });

    let hrefToStatementFile = "";
    if(userStatementViewModel.hasOwnProperty("statement_file_src") && userStatementViewModel.statement_file_src !== null && userStatementViewModel.statement_file_src !== "")
    {
        hrefToStatementFile = <span><Link to={constant.baseDomain + "/" + userStatementViewModel.statement_file_src} target="_blank">Загруженное заявление</Link></span>;
    }

    let userPosterImg = "";
    if(userStatementViewModel.hasOwnProperty("photo_file_src") && userStatementViewModel.photo_file_src !== null && userStatementViewModel.photo_file_src !== "")
    {
        userPosterImg = <img className="img-fluid user-poster" src={constant.baseDomain + "/" + userStatementViewModel.photo_file_src} alt="user-poster" />
    }

    let hrefToConfirmingOfParticipationInKinoIntellectFile = "";
    if(userStatementViewModel.hasOwnProperty("file_confirming_of_participation_in_kino_intellect_src") 
        && userStatementViewModel.file_confirming_of_participation_in_kino_intellect_src !== null 
        && userStatementViewModel.file_confirming_of_participation_in_kino_intellect_src !== ""
    ){
        hrefToConfirmingOfParticipationInKinoIntellectFile = <p><Link to={constant.baseDomain + "/" + userStatementViewModel.file_confirming_of_participation_in_kino_intellect_src} target="_blank">Файл</Link></p>;
    }

    let list_of_statement_applications_and_characteristics_files = "";
    let statement_applications_and_characteristics_file_index = 1;
    if(userStatementViewModel.userStatementApplicationsAndCharacteristicsFileViewModels !== null)
    {
        list_of_statement_applications_and_characteristics_files = <ul className="files-list">
            {userStatementViewModel.userStatementApplicationsAndCharacteristicsFileViewModels.map(userStatementApplicationsAndCharacteristicsFileViewModel => {
                if(userStatementViewModel.status === 1)
                {
                    return <li key={userStatementApplicationsAndCharacteristicsFileViewModel.index}>- <Link to={constant.baseDomain + "/" + userStatementApplicationsAndCharacteristicsFileViewModel.src} target="_blank">
                    Файл №{statement_applications_and_characteristics_file_index++}</Link>
                    </li>
                } else
                {
                    return <li key={userStatementApplicationsAndCharacteristicsFileViewModel.index}>- <Link to={constant.baseDomain + "/" + userStatementApplicationsAndCharacteristicsFileViewModel.src} target="_blank">
                    Файл №{statement_applications_and_characteristics_file_index++}</Link> - <Link className="delete" onClick={async() => {
                        await statementService.deleteApplicationsAndCharacteristicsFile(
                            jwt, 
                            setIsLoading,
                            userStatementViewModel.id, 
                            userStatementApplicationsAndCharacteristicsFileViewModel.index,
                            getCallback
                        );
                    }}>удалить</Link>
                    </li>
                }
            })}
        </ul>
    }

    let list_of_other_files = "";
    let other_file_number = 1;
    if(userStatementViewModel.userStatementOtherFileViewModels !== null)
    {
        list_of_other_files = <ul className="files-list">
            {userStatementViewModel.userStatementOtherFileViewModels.map(userStatementOtherFileViewModel => {
                if(userStatementViewModel.status === 1)
                {
                    return <li key={userStatementOtherFileViewModel.index}>- <Link to={constant.baseDomain + "/" + userStatementOtherFileViewModel.src} target="_blank">
                    Файл №{other_file_number++}</Link>
                    </li>;
                } else 
                {
                    return <li key={userStatementOtherFileViewModel.index}>- <Link to={constant.baseDomain + "/" + userStatementOtherFileViewModel.src} target="_blank">
                    Файл №{other_file_number++}</Link> - <Link className="delete" onClick={async() => {
                        await statementService.deleteOtherFile(
                            jwt, 
                            setIsLoading,
                            userStatementViewModel.id, 
                            userStatementOtherFileViewModel.index,
                            getCallback
                        );
                    }}>удалить</Link>
                    </li>;
                }
                
            })}
        </ul>
    }

    //process_data_application_file_src
    let hrefToProcessDataApplicationFile = "";
    if(userStatementViewModel.hasOwnProperty("process_data_application_file_src") && userStatementViewModel.process_data_application_file_src !== null && userStatementViewModel.process_data_application_file_src !== "")
    {
        hrefToProcessDataApplicationFile = <span><Link to={constant.baseDomain + "/" + userStatementViewModel.process_data_application_file_src} target="_blank">Загруженный файл</Link></span>;
    }


    return(
        <form className="profile" encType="multipart/form-data">

            <p className="statement-page-header">Стр. {statementPage}/6 {statementsHeaders[statementPage-1]}</p>

            <div className={"statement-page " + (statementPage === 1 ? "active" : "")}>

                <div className="form-group">
                    <label htmlFor="statement_file">Заявление участника конкурса (Формат JPG, PNG, PDF не более 1МБ)*<br />
                        <a href="/docs/Заявление участника конкурса.docx" target="_blank"> Бланк заявления </a>
                    </label>
                    
                    <br />
                    {hrefToStatementFile}
                    <br />
                    <input type="file" className={"form-control-file " + (userStatementViewModel.status === 1 ? "hide" : "")} name="statement_file" onChange={handleStatementFile} accept="image/png, image/bmp, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" ref={inputStatement} />
                </div>

                <div className="form-group">
                    <label htmlFor="nomination_id">Номинация*</label>
                    <select id="inputOrganization" name="nomination_id" className="form-control" defaultValue={userStatementViewModel.nomination_id} onChange={formListener}>
                        <option value={0}>Выбрать</option>
                        {nominationMicroOptions}
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="exampleFormControlFile1">Фотография* (Формат JPG, PNG не более 1МБ)</label>
                    <br/>
                    <i>обратите внимание, фотография в случае вашей победы будет размещена на сайте и Книге Почета</i>
                    <br />
                    <span>{ photoFile != null ? photoFile.filename : "" }</span>
                    <input type="file" className={"form-control-file " + (userStatementViewModel.status === 1 ? "hide" : "")} id="photo_file" onChange={handlePhotoFile} accept="image/*" 
                    ref={inputPhotoFile} />

                    <br />
                    {userPosterImg}
                </div>

                <div className="form-group">
                    <label>Введите ссылку на Яндекс.Диск на видеофайл с обращением к членам жюри. Не более 3 минут. Горизонтальное расположение экрана. В видеообращении расскажите о том, какие свои достижения Вы считаете наиболее выдающимися и заслуживающими победы в конкурсе по указанной номинации. Данные видеоматериалы могут быть обнародованы на информационных ресурсах конкурса и организатора (необязательное поле)</label>
                    <input type="text" name="appeal_to_jury_members" className="form-control" defaultValue={userStatementViewModel.appeal_to_jury_members} onChange={formListener} />
                </div>


            </div>


            <div className={"statement-page " + (statementPage === 2 ? "active" : "")}>

                <div className="form-group">
                    <label>Образование (образовательная организация, специальность, год окончания)*</label>
                    <input type="text" name="education" className="form-control" defaultValue={userStatementViewModel.education} onChange={formListener} />
                </div>
                <div className="form-group">
                    <label htmlFor="science_degree">Ученая степень</label>
                    <select id="science_degree" name="science_degree" className="form-control" defaultValue={userStatementViewModel.science_degree} onChange={formListener}>
                        <option value="">- не выбрано</option>
                        <option value="Не имею">Не имею</option>
                        <option value="Кандидат архитектурных наук">Кандидат архитектурных наук</option>
                        <option value="Кандидат биологических наук">Кандидат биологических наук</option>
                        <option value="Кандидат ветеринарных наук">Кандидат ветеринарных наук</option>
                        <option value="Кандидат военных наук">Кандидат военных наук</option>
                        <option value="Кандидат географических наук">Кандидат географических наук</option>
                        <option value="Кандидат геолого-минералогических наук">Кандидат геолого-минералогических наук
                        </option>
                        <option value="Кандидат искусствоведческих наук">Кандидат искусствоведческих наук</option>
                        <option value="Кандидат исторических наук">Кандидат исторических наук</option>
                        <option value="Кандидат культурологических наук">Кандидат культурологических наук</option>
                        <option value="Кандидат медицинских наук">Кандидат медицинских наук</option>
                        <option value="Кандидат педагогических наук">Кандидат педагогических наук</option>
                        <option value="Кандидат политических наук">Кандидат политических наук</option>
                        <option value="Кандидат психологических наук">Кандидат психологических наук</option>
                        <option value="Кандидат сельскохозяйственных наук">Кандидат сельскохозяйственных наук</option>
                        <option value="Кандидат социологических наук">Кандидат социологических наук</option>
                        <option value="Кандидат теологических наук">Кандидат теологических наук</option>
                        <option value="Кандидат технических наук">Кандидат технических наук</option>
                        <option value="Кандидат фармацевтических наук">Кандидат фармацевтических наук</option>
                        <option value="Кандидат физико-математических наук">Кандидат физико-математических наук</option>
                        <option value="Кандидат филологических наук">Кандидат филологических наук</option>
                        <option value="Кандидат философских наук">Кандидат философских наук</option>
                        <option value="Кандидат химических наук">Кандидат химических наук</option>
                        <option value="Кандидат экономических наук">Кандидат экономических наук</option>
                        <option value="Кандидат юридических наук">Кандидат юридических наук</option>
                        <option value="Доктор архитектурных наук">Доктор архитектурных наук</option>
                        <option value="Доктор биологических наук">Доктор биологических наук</option>
                        <option value="Доктор ветеринарных наук">Доктор ветеринарных наук</option>
                        <option value="Доктор военных наук">Доктор военных наук</option>
                        <option value="Доктор географических наук">Доктор географических наук</option>
                        <option value="Доктор геолого-минералогических наук">Доктор геолого-минералогических наук</option>
                        <option value="Доктор искусствоведческих наук">Доктор искусствоведческих наук</option>
                        <option value="Доктор исторических наук">Доктор исторических наук</option>
                        <option value="Доктор культурологических наук">Доктор культурологических наук</option>
                        <option value="Доктор медицинских наук">Доктор медицинских наук</option>
                        <option value="Доктор педагогических наук">Доктор педагогических наук</option>
                        <option value="Доктор политических наук">Доктор политических наук</option>
                        <option value="Доктор психологических наук">Доктор психологических наук</option>
                        <option value="Доктор сельскохозяйственных наук">Доктор сельскохозяйственных наук</option>
                        <option value="Доктор социологических наук">Доктор социологических наук</option>
                        <option value="Доктор теологических наук">Доктор теологических наук</option>
                        <option value="Доктор технических наук">Доктор технических наук</option>
                        <option value="Доктор фармацевтических наук">Доктор фармацевтических наук</option>
                        <option value="Доктор физико-математических наук">Доктор физико-математических наук</option>
                        <option value="Доктор филологических наук">Доктор филологических наук</option>
                        <option value="Доктор философских наук">Доктор философских наук</option>
                        <option value="Доктор химических наук">Доктор химических наук</option>
                        <option value="Доктор экономических наук">Доктор экономических наук</option>
                        <option value="Доктор юридических наук">Доктор юридических наук</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="academic_title">Ученое звание</label>
                    <select id="academic_title" name="academic_title" className="form-control" defaultValue={userStatementViewModel.academic_title} onChange={formListener}>
                        <option value="">- не выбрано</option>
                        <option value="Не имею">Не имею</option>
                        <option value="Доцент">Доцент</option>
                        <option value="Профессор">Профессор</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="academic_status">Статус РАН, РАО, РАЕ, РАЕН</label>
                    <select id="academic_status" name="academic_status" className="form-control" defaultValue={userStatementViewModel.academic_status} onChange={formListener}>
                        <option value="">- не выбрано</option>
                        <option value="Не состою">Не состою</option>
                        <option value="РАН">РАН</option>
                        <option value="РАО">РАО</option>
                        <option value="РАЕ">РАЕ</option>
                        <option value="РАЕН">РАЕН</option>
                    </select>
                </div>
                <div className="form-group">
                    <label>Другое</label>
                    <input type="text" name="academic_status_another" className="form-control" defaultValue={userStatementViewModel.academic_status_another} onChange={formListener} />
                </div>
                <div className="form-group">
                    <label>Наличие отраслевых наград, почетных званий и других отличий</label>
                    <input type="text" name="academic_rewards" className="form-control" defaultValue={userStatementViewModel.academic_rewards} onChange={formListener} />
                </div>

            </div>

            
            <div className={"statement-page " + (statementPage === 3 ? "active" : "")}>
                <div className="form-group">
                    <label>Прохождение курсов, стажировок и других мероприятий повышения квалификации за последние 3 года</label>
                    <input type="text" name="another_courses_and_other" className="form-control" defaultValue={userStatementViewModel.another_courses_and_other} onChange={formListener} />
                </div>
                <div className="form-group">
                    <label>Занимаемые должности вне образовательных организаций</label>
                    <input type="text" name="positions_outside_education" className="form-control" defaultValue={userStatementViewModel.positions_outside_education} onChange={formListener} />
                </div>
                <div className="form-group">
                    <label>Общий трудовой стаж, лет </label>
                    <input type="number" name="seniority" className="form-control" defaultValue={userStatementViewModel.seniority} onChange={formListener} />
                </div>
                <div className="form-group">
                    <label>Стаж научно-педагогической работы, лет</label>
                    <input type="number" name="pedagogical_experience" className="form-control" defaultValue={userStatementViewModel.pedagogical_experience} onChange={formListener} />
                </div>
                <div className="form-group">
                    <label>Членство в некоммерческих организациях и объединениях</label>
                    <input type="text" name="noncomerce_membership" className="form-control" defaultValue={userStatementViewModel.noncomerce_membership} onChange={formListener} />
                </div>
            </div>


            <div className={"statement-page " + (statementPage === 4 ? "active" : "")}>
                <div className="form-group">
                    <label>Являюсь экспертом (указать организацию и профиль)</label>
                    <input type="text" name="expert_of" className="form-control" defaultValue={userStatementViewModel.expert_of} onChange={formListener} />
                </div>
                <div className="form-group">
                    <label>Участие в научно-исследовательском коллективе при проведении НИР/НИОКР (год, название работы, организация)</label>
                    <input type="text" name="nir_niokr_membership" className="form-control" defaultValue={userStatementViewModel.nir_niokr_membership} onChange={formListener} />
                </div>
                <div className="form-group">
                    <label>Наличие патентов и авторских свидетельств (номер, дата выдачи, название)</label>
                    <input type="text" name="patents_and_copyrights" className="form-control" defaultValue={userStatementViewModel.patents_and_copyrights} onChange={formListener} />
                </div>
                <div className="form-group">
                    <label>Участие во всероссийских и международных конференциях за последние 3 года</label>
                    <input type="text" name="meetings_memberships_3_years" className="form-control" defaultValue={userStatementViewModel.meetings_memberships_3_years} onChange={formListener} />
                </div>
                <div className="form-group">
                    <label>Количество научных публикаций в ведущих рецензируемых журналах, входящих в список ВАК (шт. всего / в том числе за последние 3 года)</label>
                    <input type="text" name="magazine_publications_count" className="form-control" defaultValue={userStatementViewModel.magazine_publications_count} onChange={formListener} />
                </div>
                <div className="form-group">
                    <label>Ссылка на профиль автора на сайте https://www.elibrary.ru (например, https://www.elibrary.ru/author_profile.asp?authorid=1149367)</label>
                    <input type="text" name="link_to_elibrary_author_profile" className="form-control" defaultValue={userStatementViewModel.link_to_elibrary_author_profile} onChange={formListener} />
                </div>

                <div className="form-group">
                    <label>Количество публикаций в РИНЦ</label>
                    <input type="text" name="rints_publications_count" className="form-control" defaultValue={userStatementViewModel.rints_publications_count} onChange={formListener} />
                </div>
                <div className="form-group">
                    <label>Количество цитирований моих работ по РИНЦ</label>
                    <input type="text" name="rints_citations_count" className="form-control" defaultValue={userStatementViewModel.rints_citations_count} onChange={formListener} />
                </div>
                <div className="form-group">
                    <label>Индекс Хирша в РИНЦ</label>
                    <input type="text" name="hirsh_index" className="form-control" defaultValue={userStatementViewModel.hirsh_index} onChange={formListener} />
                </div>
                <div className="form-group">
                    <label>Перечень опубликованных учебников и учебных пособий (автор, соавтор, редактор)</label>
                    <input type="text" name="published_textbooks_tutorials" className="form-control" defaultValue={userStatementViewModel.published_textbooks_tutorials} onChange={formListener} />
                </div>
                <div className="form-group">
                    <label>Разработка нового учебного курса, участие в разработке новой учебной программы (годы, название, краткое описание, результат внедрения) </label>
                    <input type="text" name="development_study_courses" className="form-control" defaultValue={userStatementViewModel.development_study_courses} onChange={formListener} />
                </div>
                <div className="form-group">
                    <label>Являюсь разработчиком проектов, направленных на развитие и повышение качества высшего образования (годы, название, краткое описание, результат внедрения) </label>
                    <input type="text" name="development_study_projects" className="form-control" defaultValue={userStatementViewModel.development_study_projects} onChange={formListener} />
                </div>
            </div>


            <div className={"statement-page " + (statementPage === 5 ? "active" : "")}>
                
                <div className="form-group">
                    <label htmlFor="practice_oriented_teaching">Ведение практико-ориентированной преподавательской деятельности
                    </label>
                    <input type="text" className="form-control" id="practice_oriented_teaching" name="practice_oriented_teaching" defaultValue={userStatementViewModel.practice_oriented_teaching} onChange={formListener} />
                </div>

                <div className="form-group hide">
                    <label htmlFor="technical_and_presentation_equipment_use_degree">Степень использования технического и презентационного
                    оборудования</label>
                    <input type="text" className="form-control" id="technical_and_presentation_equipment_use_degree"
                    name="technical_and_presentation_equipment_use_degree" defaultValue={userStatementViewModel.technical_and_presentation_equipment_use_degree} onChange={formListener} />
                </div>

                <div className="form-group hide">
                    <label htmlFor="internet_and_professional_activities_use_degree">Степень использования сети Интернет в профессиональной
                    деятельности </label>
                    <input type="text" className="form-control" id="internet_and_professional_activities_use_degree"
                    name="internet_and_professional_activities_use_degree" defaultValue={userStatementViewModel.internet_and_professional_activities_use_degree} onChange={formListener} />
                </div>
                
                <div className="form-group">
                    <label htmlFor="digital_training_courses">Наличие цифровых учебных курсов, используемых в смешанном или дистанционном образовательном процессе </label>
                    <input type="text" className="form-control" id="digital_training_courses" name="digital_training_courses" defaultValue={userStatementViewModel.digital_training_courses} onChange={formListener} />
                </div>

                <div className="form-group">
                    <label htmlFor="study_improvement_information_resource">Наличие информационного ресурса, используемого для повышения качества образовательного процесса </label>
                    <input type="text" className="form-control" id="study_improvement_information_resource" name="study_improvement_information_resource" defaultValue={userStatementViewModel.study_improvement_information_resource} onChange={formListener} />
                </div>

                <div className="form-group">
                    <label htmlFor="innovative_approach_teaching_development">Разработка и внедрение инновационного подхода к преподаванию и/или инновационных методов обучения</label>
                    <input type="text" className="form-control" id="innovative_approach_teaching_development" name="innovative_approach_teaching_development" defaultValue={userStatementViewModel.innovative_approach_teaching_development} onChange={formListener} />
                </div>

                <div className="form-group">
                    <label htmlFor="interactive_teaching_methods">Использование интерактивных методов обучения вовлечения учащихся в учебный процесс, расширение традиционных преподавательских практик (указать какие) </label>
                    <input type="text" className="form-control" id="interactive_teaching_methods" name="interactive_teaching_methods" defaultValue={userStatementViewModel.interactive_teaching_methods} onChange={formListener} />
                </div>

                <div className="form-group">
                    <label htmlFor="is_participated_of_kino_intellect">Принял участие  в Международном кинофестивале учебных фильмов и инновационного образовательного контента "КиноИнтеллект"</label>
                    <select id="is_participated_of_kino_intellect" name="is_participated_of_kino_intellect" className="form-control" defaultValue={userStatementViewModel.is_participated_of_kino_intellect} onChange={formListener}>
                        <option value="">- не выбрано</option>
                        <option value="Не принимал">Не принимал</option>
                        <option value="Участник конкурсной программы">Участник конкурсной программы</option>
                        <option value="Участник внеконкурсной программы">Участник внеконкурсной программы</option>
                        <option value="Дипломант">Дипломант</option>
                        <option value="Лауреат">Лауреат</option>
                        <option value="Член жюри">Член жюри</option>
                    </select>
                </div>

<div className="form-group">
    <label htmlFor="file_confirming_of_participation_in_kino_intellect">
        Загрузите копию сертификата или диплома, подтверждающих результат участия в кинофестивале "КиноИнтеллект"
    </label> 
    <br />

    {hrefToConfirmingOfParticipationInKinoIntellectFile}

    <input type="file" className={"form-control-file " + (userStatementViewModel.status === 1 ? "hide" : "")} id="file_confirming_of_participation_in_kino_intellect" name="file_confirming_of_participation_in_kino_intellect" accept="image/png, image/bmp, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    //multiple
    ref={inputConfirmingOfParticipationInKinoIntellectFile}
    onChange={handleConfirmingOfParticipationInKinoIntellectFile} />
</div>

                <div className="form-group">
                    <label htmlFor="graduated_masters_under_my_supervision">Количество магистрантов, успешно защитивших магистерскую диссертацию под  моим руководством</label>
                    <input type="number" className="form-control" id="graduated_masters_under_my_supervision" name="graduated_masters_under_my_supervision" defaultValue={userStatementViewModel.graduated_masters_under_my_supervision} onChange={formListener} />
                </div>

                <div className="form-group">
                    <label htmlFor="graduated_students_under_my_supervision">Количество аспирантов, успешно защитивших кандидатскую диссертацию под моим руководством</label>
                    <input type="number" className="form-control" id="graduated_students_under_my_supervision" name="graduated_students_under_my_supervision" defaultValue={userStatementViewModel.graduated_students_under_my_supervision} onChange={formListener} />
                </div>

                <div className="form-group">
                    <label htmlFor="graduated_doctoral_under_my_supervision">Количество докторантов, успешно защитивших докторскую диссертацию под моим руководством</label>
                    <input type="number" className="form-control" id="graduated_doctoral_under_my_supervision" name="graduated_doctoral_under_my_supervision" defaultValue={userStatementViewModel.graduated_doctoral_under_my_supervision} onChange={formListener} />
                </div>

                <div className="mb-3">
                    <label htmlFor="description_of_involvement_in_academic_mentoring_activities">Опишите своё участие в деятельности по академическому наставничеству (проекты, консультирование молодых преподавателей и учёных, развитие теории и практики наставничества)</label>
                    <textarea className="form-control " id="description_of_involvement_in_academic_mentoring_activities" maxLength="5000" placeholder="" name="description_of_involvement_in_academic_mentoring_activities" defaultValue={userStatementViewModel.description_of_involvement_in_academic_mentoring_activities} onChange={formListener}></textarea>
                </div>


                <div className="form-group">
                    <label htmlFor="is_ready_to_enter_national_database_of_academic_mentors">Готов войти в Национальную базу академических наставников для консультирования начинающих преподавателей и учёных</label>
                    <select id="is_ready_to_enter_national_database_of_academic_mentors" name="is_ready_to_enter_national_database_of_academic_mentors" className="form-control" defaultValue={userStatementViewModel.is_ready_to_enter_national_database_of_academic_mentors} onChange={formListener}>
                        <option value="">- не выбрано</option>
                        <option value="Да">Да</option>
                        <option value="Нет">Нет</option>
                    </select>
                </div>

                <div className="mb-3">
                    <label htmlFor="projects_with_me_for_db_of_best_practices_for_academic_mentoring">Предлагаю внести в Базу лучших практик Академического наставничества проект/инициативу, реализованную с моим участием</label>
                    <textarea className="form-control " id="projects_with_me_for_db_of_best_practices_for_academic_mentoring" maxLength="5000" placeholder="" name="projects_with_me_for_db_of_best_practices_for_academic_mentoring" defaultValue={userStatementViewModel.projects_with_me_for_db_of_best_practices_for_academic_mentoring} onChange={formListener}></textarea>
                </div>

                <div className="mb-3">
                    <label htmlFor="work_with_schoolchildren_who_have_interest_in_science">Осуществляю работу со школьниками, проявившими интерес к научным исследованиям (Укажите в рамках какого проекта, суть и содержание такой работы, при наличии её результаты)</label>
                    <textarea className="form-control " id="work_with_schoolchildren_who_have_interest_in_science" maxLength="5000" placeholder="" name="work_with_schoolchildren_who_have_interest_in_science" defaultValue={userStatementViewModel.work_with_schoolchildren_who_have_interest_in_science} onChange={formListener}></textarea>
                </div>

                

                
                <div className="form-group">
                    <label htmlFor="participant_in_international_educational_projects">Участие в международных образовательных проектах </label>
                    <input type="text" className="form-control" id="participant_in_international_educational_projects" name="participant_in_international_educational_projects" defaultValue={userStatementViewModel.participant_in_international_educational_projects} onChange={formListener} />
                </div>

                <div className="form-group">
                    <label htmlFor="international_partnership_experience">Опыт работы в международных партнерских коллективах </label>
                    <input type="text" className="form-control" id="international_partnership_experience" name="international_partnership_experience" defaultValue={userStatementViewModel.international_partnership_experience} onChange={formListener} />
                </div>

                <div className="form-group">
                    <label htmlFor="student_scientific_competitive_and_olympiad_movement_participant">Участие в развитии студенческого научного, конкурсного и олимпиадного движения </label>
                    <input type="text" className="form-control" id="student_scientific_competitive_and_olympiad_movement_participant" name="student_scientific_competitive_and_olympiad_movement_participant" defaultValue={userStatementViewModel.student_scientific_competitive_and_olympiad_movement_participant} onChange={formListener} />
                </div>

                <div className="form-group">
                    <label htmlFor="vocational_guidance_participant">Участие в профессиональной ориентации детей и молодежи </label>
                    <input type="text" className="form-control" id="vocational_guidance_participant" name="vocational_guidance_participant" defaultValue={userStatementViewModel.vocational_guidance_participant} onChange={formListener} />
                </div>

                <div className="form-group">
                    <label htmlFor="patriotic_projects_experience">Опыт реализации патриотических проектов </label>
                    <input type="text" className="form-control" id="patriotic_projects_experience" name="patriotic_projects_experience" defaultValue={userStatementViewModel.patriotic_projects_experience} onChange={formListener} />
                </div>

                <div className="form-group">
                    <label htmlFor="educational_activities_participation">Участие в просветительской деятельности </label>
                    <input type="text" className="form-control" id="educational_activities_participation" name="educational_activities_participation" defaultValue={userStatementViewModel.educational_activities_participation} onChange={formListener} />
                </div>

            </div>


            <div className={"statement-page " + (statementPage === 6 ? "active" : "")}>

                <div className="form-group">
                    <label htmlFor="other_social_public_educational_work">Прочая социальная, общественная, воспитательная работа</label>
                    <input type="text" className="form-control" id="other_social_public_educational_work" name="other_social_public_educational_work" defaultValue={userStatementViewModel.other_social_public_educational_work} onChange={formListener} />
                </div>

                <div className="form-group">
                    <label htmlFor="pedagogical_dynasty">Являюсь представителем педагогической династии</label>
                    <input type="text" className="form-control" id="pedagogical_dynasty" name="pedagogical_dynasty" defaultValue={userStatementViewModel.pedagogical_dynasty} onChange={formListener} />
                </div>


                <div className="form-group">
                    <label htmlFor="applications_and_characteristics_files">
                        Ходатaйства и характеристики по одному до 40 шт. (Формат JPG, PNG, PDF не более 1МБ каждый)
                        <br /> 
                        <Link to="/docs/Образец ходатайства.pdf" target="_blank"> Образец ходатaйства </Link> 
                    </label> 
                    <br />

                    {list_of_statement_applications_and_characteristics_files}

                    <input type="file" className={"form-control-file " + (userStatementViewModel.status === 1 ? "hide" : "")} id="applications_and_characteristics_files" name="applications_and_characteristics_files" accept="image/png, image/bmp, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    //multiple
                    ref={inputApplicationsAndCharacteristicFile}
                    onChange={handleApplicationsAndCharacteristicFileChange} />
                </div>

                <div className="form-group">
                    <label htmlFor="other_files">Дополнительные файлы по одному до 40 шт. (Формат JPG, PNG, PDF не более 1МБ
                    каждый)</label> <br />

                    {list_of_other_files}

                    <input type="file" className={"form-control-file " + (userStatementViewModel.status === 1 ? "hide" : "")} id="other_files" name="other_files" accept="image/png, image/bmp, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" 
                    //multiple 
                    ref={inputOtherFile}
                    onChange={handleOtherFileChange} />
                </div>

                <div className="form-group">
                    <label htmlFor="criminal_records">Сведения о судимости и нахождении под арестом*</label>
                    <select id="criminal_records" className="form-control" name="criminal_records" defaultValue={userStatementViewModel.criminal_records} onChange={formListener}>
                        <option value={0}>Выбрать</option>
                        <option value={1}>Не имею</option>
                        <option value={2}>Имею</option>
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="professional_disqualification_records">Сведения о профессиональной дисквалификации*</label>
                    <select id="professional_disqualification_records" className="form-control" name="professional_disqualification_records" defaultValue={userStatementViewModel.professional_disqualification_records} onChange={formListener}>
                        <option value={0}>Выбрать</option>
                        <option value={1}>Не имею</option>
                        <option value={2}>Имею</option>
                    </select>
                </div>

                <div className="mb-3">
                    <label htmlFor="essay">Эссе (Максимально 5000 символов)</label>
                    <textarea className="form-control " id="essay" maxLength="5000" placeholder="" name="essay" defaultValue={userStatementViewModel.essay} onChange={formListener}></textarea>
                </div>

                <hr />

                <div className="form-group">
                    <p>
                    Просим проверить корректность данных, как должна отображаться запись о Вас в Книге Почета
                    преподавателей вузов Российской Федерации в случае Вашей победы в конкурсе: <br />
                    - Фамилия, Имя, Отчество,<br />
                    - Ученая степень и звание,<br />
                    - Полное наименование ВУЗа,<br />
                    - Город.
                    </p>
                </div>


                <hr />

                <div className="form-group">
                    <label htmlFor="process_data_application_file">Заявление на разрешение обработки персональных данных* (Формат JPG, PNG, PDF не более 1МБ) <Link to="/docs/Согласие на обработку персональных данных.pdf" target="_blank"> Бланк заявления </Link></label> <br />
                    {hrefToProcessDataApplicationFile}
                    <br />
                    <input type="file" className={"form-control-file " + (userStatementViewModel.status === 1 ? "hide" : "")} id="process_data_application_file" name="process_data_application_file" accept="image/png, image/bmp, image/jpeg, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={handleProcessDataApplicationFile} />
                </div>

            </div>


            <div className={"statement-page " + (statementPage === 7 ? "active" : "")}>
                <div className="not-pass">
                    Данная страница будет доступна для просмотра и заполнения после заполнения всех остальных полей заявки. "Если вы уже заполнили все поля, но страница не открывается и вы видите этот текст, то обратитесь в службу технической поддержки по телефону +7(499) 495-17-65"
                </div>
            </div>

            <div className="statement-control">
                <Button variant={"default " + (statementPage <= 1 || isSubLoading ? "disabled" : "")} disabled={statementPage <= 1 ? "disabled" : ""} onClick={() => changePage(false)}>Пред</Button>
                <Button variant={"default " + (userStatementViewModel.status === 1 ? "hide" : "")} disabled={isLoading || isSaving} onClick={async() => {
                    await statementService.update(
                        jwt,
                        setIsSaving,
                        setWarning,
                        getCallback,
                        contest_id,
                        userStatementViewModel,

                        statementFile,
                        inputStatement,
                        setStatementFile,

                        photoFile,
                        inputPhotoFile,
                        setPhotoFile,

                        confirmingOfParticipationInKinoIntellectFile,
                        inputConfirmingOfParticipationInKinoIntellectFile,
                        setConfirmingOfParticipationInKinoIntellectFile,

                        //applicationsAndCharacteristicFiles,
                        //setApplicationsAndCharacteristicFiles,
                        applicationsAndCharacteristicFile,
                        inputApplicationsAndCharacteristicFile,
                        setApplicationsAndCharacteristicFile,

                        //otherFiles,
                        //setOtherFiles,
                        otherFile,
                        inputOtherFile,
                        setOtherFile,

                        processDataApplicationFile,
                        setProcessDataApplicationFile,
                    )
                }}>Сохранить</Button>
                <Button variant={"default " + (statementPage >= 6 || isSubLoading ? "disabled" : "")} onClick={() => changePage(true)}>След</Button>
                <br />
                <Button variant={"default " + (statementPage < 6 || userStatementViewModel.status === 1 ? "hide" : isSubLoading ? "disabled" : "")} onClick={async() => await statementService.confirm(
                    jwt,
                    isSubLoading,
                    setIsSubLoading,
                    setWarning,
                    userStatementViewModel.id,
                    navigate
                )}>Подать заявку</Button>
                <br />
                <p>{warning}</p>
            </div>

        </form>
    )
}

/*

                <div className="form-group">
                    <label>XXXXXXXXXXXXXX</label>
                    <input type="text" name="XXXXXXXXXXXXXXXXXX" className="form-control" defaultValue={userStatementViewModel.XXXXXXXXXX} />
                </div>
                <div className="form-group">
                    <label>XXXXXXXXXXXXXX</label>
                    <input type="text" name="XXXXXXXXXXXXXXXXXX" className="form-control" defaultValue={userStatementViewModel.XXXXXXXXXX} />
                </div>
                <div className="form-group">
                    <label>XXXXXXXXXXXXXX</label>
                    <input type="text" name="XXXXXXXXXXXXXXXXXX" className="form-control" defaultValue={userStatementViewModel.XXXXXXXXXX} />
                </div>
*/