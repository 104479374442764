import constant from "../utils/GlobalValues";


export default class StatementService
{


    async confirm(
        jwt, 
        isSubLoading,
        setIsSubLoading,
        setWarning,
        statement_id,
        navigate
    ){
        if(isSubLoading)
        {
            return;
        }
        setIsSubLoading(true);
        setWarning("");
        try
        {
            await fetch(constant.baseDomain + "/api/user/statement/confirm/" + statement_id,
            {
                method: 'GET',
                headers: {
                    'Authorization' : 'Bearer ' + jwt,
                    'Content-Type': 'application/json'
                },
                //redirect: 'follow',
                //referrerPolicy: 'no-referrer'
            })
            .then(res => res.json())
            .then((jsonAnswerStatus) => {
                if(jsonAnswerStatus.status === "success")
                {
                    navigate("/contests");
                } else if(jsonAnswerStatus.status === "error" && jsonAnswerStatus.errors === "no_statement_file")
                {
                    setWarning("Не отправлено заявление на 1-ой странице");
                } else if(jsonAnswerStatus.status === "error" && jsonAnswerStatus.errors === "no_nomination_id")
                {
                    setWarning("Не указана номинация на 1-ой странице");
                } else if(jsonAnswerStatus.status === "error" && jsonAnswerStatus.errors === "no_photo_file")
                {
                    setWarning("Не отправлено фото на 1-ой странице");
                } else if(jsonAnswerStatus.status === "error" && jsonAnswerStatus.errors === "no_education")
                {
                    setWarning("Не указано образование на 2-ой странице");
                } else if(jsonAnswerStatus.status === "error" && jsonAnswerStatus.errors === "no_criminal_records")
                {
                    setWarning("Не указаны сведения о судимости и нахождении под арестом на данной 6-ой странице");
                } else if(jsonAnswerStatus.status === "error" && jsonAnswerStatus.errors === "no_professional_disqualification_records")
                {
                    setWarning("Не указаны сведения о профессиональной дисквалификации на данной 6-ой странице");
                } else if(jsonAnswerStatus.status === "error" && jsonAnswerStatus.errors === "no_process_data_application_file")
                {
                    setWarning("Не отправлено заявление на разрешение обработки персональных данных на данной 6-ой странице");
                } else
                {
                    setWarning("Неизвестная ошибка на сервере");
                }
            })
            .catch((error) => {
                console.log(error);
                setWarning("Неизвестная ошибка на сервере");
            })
        } catch(error)
        {
            console.log(error);
            setWarning("Неизвестная ошибка на сервере");
        } finally
        {
            setIsSubLoading(false);
        }
    }


    async withdraw(
        jwt, 
        setIsLoading,
        contest_id,
        list_of_contests_callback
    ){
        setIsLoading(true);
        try
        {
            await fetch(constant.baseDomain + "/api/user/statement/contest/"+contest_id+"/withdraw",
            {
                method: 'GET',
                headers: {
                    'Authorization' : 'Bearer ' + jwt,
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            })
            .then(res => res.json())
            .then((jsonAnswerStatus) => {
                if(jsonAnswerStatus.status === "success")
                {
                    if(list_of_contests_callback !== null)
                    {
                        list_of_contests_callback();
                    }
                } else
                {
                    alert("Неизвестная ошибка на сервере");
                }
            })
            .catch((error) => {
                console.log(error);
                alert("Неизвестная ошибка на сервере");
            })
        } catch(error)
        {
            console.log(error);
            alert("Неизвестная ошибка на сервере");
        } finally
        {
            setIsLoading(false);
        }
    }


    async update(
        jwt, 
        setIsLoading,
        setWarning,
        getCallback,
        contest_id,
        userStatementViewModel,

        statement_file,
        inputStatement,
        setStatementPage,

        photo_file,
        inputPhotoFile,
        setPhotoFile,

        confirmingOfParticipationInKinoIntellectFile,
        inputConfirmingOfParticipationInKinoIntellectFile,
        setConfirmingOfParticipationInKinoIntellectFile,

        //applicationsAndCharacteristicFiles,
        //setApplicationsAndCharacteristicFiles,
        applicationsAndCharacteristicFile,
        inputApplicationsAndCharacteristicFile,
        setApplicationsAndCharacteristicFile,

        //otherFiles,
        //setOtherFiles,
        otherFile,
        inputOtherFile,
        setOtherFile,

        processDataApplicationFile,
        setProcessDataApplicationFile,
    ){
        setIsLoading(true);
        setWarning("Идет сохранение...");

        let data = new FormData()
        //data.append('file', input.files[0])
        //console.log(userStatementViewModel);
        for (let key in userStatementViewModel ) {
            //console.log(key + " : " + userStatementViewModel[key]);
            if(userStatementViewModel[key] === "null" || userStatementViewModel[key] === null)
            {
                continue;
            }
            data.append(key, userStatementViewModel[key]);
        }

        if(statement_file !== null && typeof(statement_file) !== "undefined")
        {
            data.append('statement_file', statement_file)
        }

        if(photo_file !== null && typeof(photo_file) !== "undefined")
        {
            data.append('photo_file', photo_file)
        }

        if(confirmingOfParticipationInKinoIntellectFile !== null && typeof(confirmingOfParticipationInKinoIntellectFile) !== "undefined")
        {
            data.append('file_confirming_of_participation_in_kino_intellect', confirmingOfParticipationInKinoIntellectFile)
        }

        /*
        if(applicationsAndCharacteristicFiles !== null && typeof(applicationsAndCharacteristicFiles) !== "undefined")
        {
            for (let i = 0; i < applicationsAndCharacteristicFiles.length; i++) {
                data.append('applications_and_characteristics_files', applicationsAndCharacteristicFiles[i]);
            }
        }
        */
        if(applicationsAndCharacteristicFile !== null && typeof(applicationsAndCharacteristicFile) !== "undefined")
        {
            data.append('applications_and_characteristics_file', applicationsAndCharacteristicFile);
        }

        /*
        if(otherFiles !== null && typeof(otherFiles) !== "undefined")
        {
            let other_files_list = [];
            for (let i = 0; i < otherFiles.length; i++) 
            {
                //data.append('other_files', otherFiles[i]);
                other_files_list.push(otherFiles[i]);
            }
            data.append('other_files', otherFiles);
        }
        */
        if(otherFile !== null && typeof(otherFile) !== "undefined")
        {
            data.append('other_file', otherFile);
        }

        if(processDataApplicationFile !== null && typeof(processDataApplicationFile) !== "undefined")
        {
            data.append('process_data_application_file', processDataApplicationFile)
        }

        try
        {
            await fetch(constant.baseDomain + "/api/user/statement/contest/" + contest_id,
            {
                method: 'PUT',
                headers: {
                    'Authorization' : 'Bearer ' + jwt,
                    //'Content-Type': 'application/json'
                },
                //redirect: 'follow',
                //referrerPolicy: 'no-referrer',
                body: data
                /*
                body: JSON.stringify({
                    "id" : userStatementViewModel.id,
                    "user_id" : userStatementViewModel.user_id,
                    "contest_id" : userStatementViewModel.contest_id,
                    "status" : userStatementViewModel.status,

                    "nomination_id" : userStatementViewModel.nomination_id,
                    "league_participation_is_membership" : userStatementViewModel.league_participation_is_membership,
                    "league_participation_membership_status" : userStatementViewModel.league_participation_membership_status,
                    "username" : userStatementViewModel.username,
                    "secondname" : userStatementViewModel.secondname,
                    "firstname" : userStatementViewModel.firstname,
                    "patronymic" : userStatementViewModel.patronymic,
                    "gender" : userStatementViewModel.gender,
                    "password_new" : userStatementViewModel.passwordNew,
                    "password_current" : userStatementViewModel.passwordCurrent
                })
                */
            })
            .then(res => res.json())
            .then((jsonAnswerStatus) => {
                if(jsonAnswerStatus.status === "success")
                {
                    setWarning("Успешно сохранено");
                    setStatementPage(null);
                    if(inputStatement !== null)
                    {
                        inputStatement.current.value = "";
                    }
                    setPhotoFile(null);
                    if(inputPhotoFile !== null)
                    {
                        inputPhotoFile.current.value = "";
                    }

                    setConfirmingOfParticipationInKinoIntellectFile(null);
                    if(inputConfirmingOfParticipationInKinoIntellectFile != null)
                    {
                        inputConfirmingOfParticipationInKinoIntellectFile.current.value = "";
                    }

                    //setApplicationsAndCharacteristicFiles(null);
                    setApplicationsAndCharacteristicFile(null);
                    if(inputApplicationsAndCharacteristicFile !== null)
                    {
                        inputApplicationsAndCharacteristicFile.current.value = "";
                    }

                    setOtherFile(null);
                    if(inputOtherFile !== null)
                    {
                        inputOtherFile.current.value = "";
                    }
                    
                    setProcessDataApplicationFile(null);
                    if(getCallback !== null && 
                        (
                            (statement_file !== null && typeof(statement_file) !== "undefined")
                            || (photo_file !== null && typeof(photo_file) !== "undefined")
                            || (confirmingOfParticipationInKinoIntellectFile !== null && typeof(confirmingOfParticipationInKinoIntellectFile) !== "undefined")
                            || (applicationsAndCharacteristicFile !== null && typeof(applicationsAndCharacteristicFile) !== "undefined")
                            || (otherFile !== null && typeof(otherFile) !== "undefined")
                        )
                    ){
                        getCallback();
                    }

                } else if(jsonAnswerStatus.status === "error" && jsonAnswerStatus.errors !== null)
                {
                    setWarning("Ошибка сохранения: " + jsonAnswerStatus.errors);
                } else
                {
                    setWarning("Ошибка сохранения");
                }
            })
            .catch((error) => {
                console.log(error);
                setWarning("Ошибка сохранения");
            })
        } catch(error)
        {
            console.log(error);
            setWarning("Ошибка сохранения");
        } finally
        {
            setIsLoading(false);
        }
    }

    async get(
        jwt, 
        setIsLoading,
        setIsError,
        contest_id,
        setNominationMicroViewModels,
        setUserStatementViewModel,
    ){
        setIsLoading(true);
        setIsError(false);
        try
        {
            await fetch(constant.baseDomain + "/api/user/statement/contest/" + contest_id,
            {
                method: 'GET',
                headers: {
                    'Authorization' : 'Bearer ' + jwt,
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            })
            .then(res => res.json())
            .then((jsonAnswerStatus) => {
                if(jsonAnswerStatus.status === "success" && jsonAnswerStatus.userStatementViewModel !== null)
                {
                    if(jsonAnswerStatus.nominationMicroViewModels !== null)
                    {
                        setNominationMicroViewModels(jsonAnswerStatus.nominationMicroViewModels);
                    }
                    setUserStatementViewModel(jsonAnswerStatus.userStatementViewModel);
                } else 
                {
                    setIsError(true);
                }
            })
            .catch((error) => {
                console.log(error);
                setIsError(true);
            })
        } catch(error)
        {
            console.log(error);
            setIsError(true);
        } finally
        {
            setIsLoading(false);
        }
    }

    async deleteOtherFile(
        jwt, 
        setIsLoading,
        statement_id,
        file_index,
        getCallback,
    )
    {
        setIsLoading(true);
        try
        {
            await fetch(constant.baseDomain + "/api/user/statement/" + statement_id + "/other_file/" + file_index,
            {
                method: 'DELETE',
                headers: {
                    'Authorization' : 'Bearer ' + jwt,
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            })
            .then(res => res.json())
            .then((jsonAnswerStatus) => {
                if(jsonAnswerStatus.status === "success")
                {
                    getCallback();
                } else 
                {
                    setIsLoading(false);
                    alert("Неизвестная ошибка на сервере");
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            })
        } catch(error)
        {
            setIsLoading(false);
            console.log(error);
        } finally
        {

        }
    }

    async deleteApplicationsAndCharacteristicsFile(
        jwt, 
        setIsLoading,
        statement_id,
        file_index,
        getCallback,
    )
    {
        setIsLoading(true);
        try
        {
            await fetch(constant.baseDomain + "/api/user/statement/" + statement_id + "/applications_and_characteristics_file/" + file_index,
            {
                method: 'DELETE',
                headers: {
                    'Authorization' : 'Bearer ' + jwt,
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            })
            .then(res => res.json())
            .then((jsonAnswerStatus) => {
                if(jsonAnswerStatus.status === "success")
                {
                    getCallback();
                } else 
                {
                    setIsLoading(false);
                    alert("Неизвестная ошибка на сервере");
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            })
        } catch(error)
        {
            setIsLoading(false);
            console.log(error);
        } finally
        {

        }
    }
}