import constant from "../utils/GlobalValues";

export default class OnlineAuditoriumService
{
    async getLite(
        jwt,
        setIsLoading, 
        setIsError,
        isLaunched,
        setIsLaunched,
        youtubeVideoId,
        setYoutubeVideoId,
        isOpen,
        setIsOpen,
        lastDateOfChat,
        updateChatCallback
    ){
        console.log("OnlineAuditoriumService getLite isOpen: " + isOpen + "; youtubeVideoId: " + youtubeVideoId + "; lastDateOfChat: " + lastDateOfChat);
        setIsLoading(true);
        setIsError(false);
        try
        {
            await fetch(constant.baseDomain + "/api/online_auditorium/user/get_lite",
            {
                method: 'GET',
                headers: {
                    'Authorization' : 'Bearer ' + jwt,
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            })
            .then(res => res.json())
            .then((jsonAnswerStatus) => {
                if(jsonAnswerStatus.status === "success" && jsonAnswerStatus.onlineAuditoriumDataLiteViewModel !== null)
                {
                    if(jsonAnswerStatus.onlineAuditoriumDataLiteViewModel.youtube_video_id !== null 
                        && typeof(jsonAnswerStatus.onlineAuditoriumDataLiteViewModel.youtube_video_id) !== "undefined")
                    {
                        if(setYoutubeVideoId !== null && youtubeVideoId !== jsonAnswerStatus.onlineAuditoriumDataLiteViewModel.youtube_video_id)
                        {
                            console.log("youtube not matched | jsonAnswerStatus.onlineAuditoriumDataLiteViewModel.youtube_video_id: "
                             + jsonAnswerStatus.onlineAuditoriumDataLiteViewModel.youtube_video_id);
                            setYoutubeVideoId(jsonAnswerStatus.onlineAuditoriumDataLiteViewModel.youtube_video_id);
                        }
                    }
                    if(jsonAnswerStatus.onlineAuditoriumDataLiteViewModel.is_open !== null 
                        && typeof(jsonAnswerStatus.onlineAuditoriumDataLiteViewModel.is_open) !== "undefined")
                    {
                        if(setIsOpen !== null && isOpen !== jsonAnswerStatus.onlineAuditoriumDataLiteViewModel.is_open)
                        {
                            console.log("isOpen not matched");
                            setIsOpen(jsonAnswerStatus.onlineAuditoriumDataLiteViewModel.is_open);

                        }

                        if(isOpen && jsonAnswerStatus.onlineAuditoriumDataLiteViewModel.last_date_of_chat !== null 
                            && typeof(jsonAnswerStatus.onlineAuditoriumDataLiteViewModel.last_date_of_chat) !== "undefined")
                        {
                            if(updateChatCallback !== null && lastDateOfChat !== jsonAnswerStatus.onlineAuditoriumDataLiteViewModel.last_date_of_chat)
                            {
                                console.log("OnlineAuditoriumService getLite call updateChatCallback()");
                                updateChatCallback();
                            }
                        }
                    }
                    if(setIsLaunched !== null && !isLaunched)
                    {
                        setIsLaunched(true);
                    }
                } else 
                {
                    if(!isLaunched)
                    {
                        setIsError(true);
                    }
                }
            })
            .catch((error) => {
                console.log(error);
                if(!isLaunched)
                {
                    setIsError(true);
                }
            })
        } catch(error)
        {
            console.log(error);
            if(!isLaunched)
            {
                setIsError(true);
            }
        } finally
        {
            setIsLoading(false);
        }
    }
}