import { useEffect, useState } from "react"
import UserService from "../services/UserService";
import UserMiddleware from "../utils/UserMiddleware";
import { SystemLoadingPage } from "../components/SystemLoadingPage";
import { SystemErrorPage } from "../components/SystemErrorPage";
import { Link } from "react-router-dom";


export function DashboardPage(props)
{
    const [isProfileLoading, setIsProfileLoading] = useState(false);
    const [isError, setIsError] = useState();
    const [isSaving, setIsSaving] = useState(false);
    const [statusOfUpdate, setStatusOfUpdate] = useState();

    const [leagueParticipationMembershipStatusMicroViewModels, setLeagueParticipationMembershipStatusMicroViewModels] = useState([]);
    const [regionMicroViewModels, setRegionMicroViewModels] = useState([]);
    const [universityMicroViewModels, setUniversityMicroViewModels] = useState([]);

    const [userProfileLiteViewModel, setUserProfileLiteViewModel] = useState({
        username : "",
        secondname : "",
        firstname : "",
        patronymic : "",
        password_new : "",
        password_current : ""
    });


    useEffect(() => {
        //console.log("username: " + userProfileLiteViewModel.username);
        //console.log("isError: " + isError);
        setStatusOfUpdate("");
    }, [isProfileLoading, isError, userProfileLiteViewModel]);

    const userService = new UserService();
    const userMiddleware = new UserMiddleware();
    const jwt = userMiddleware.getJWTFromCookie();

    const formListener = (e) => {
        let copiedUserProfileLiteViewModel = {...userProfileLiteViewModel};
        copiedUserProfileLiteViewModel[e.target.name] = e.target.value;
        setUserProfileLiteViewModel(() => ({
            ...copiedUserProfileLiteViewModel
        }));
        /*
        switch(e.target.name)
        {
            case "username":
                setUserProfileLiteViewModel({...userProfileLiteViewModel, username : e.target.value});
                break;
            case "secondname":
                setUserProfileLiteViewModel({...userProfileLiteViewModel, secondname : e.target.value});
                break;
            case "firstname":
                setUserProfileLiteViewModel({...userProfileLiteViewModel, firstname : e.target.value});
                break;
            case "patronymic":
                setUserProfileLiteViewModel({...userProfileLiteViewModel, patronymic : e.target.value});
                break;
            case "password_new":
                setUserProfileLiteViewModel({...userProfileLiteViewModel, password_new : e.target.value});
                break;
            case "password_current":
                setUserProfileLiteViewModel({...userProfileLiteViewModel, password_current : e.target.value});
                break;
            default:
                break;
        }
        */
    }
    
    useEffect(() => {
        const userMiddleware = new UserMiddleware();
        const jwt = userMiddleware.getJWTFromCookie();

        async function profileGet(){
            const userService = new UserService();
            await userService.profileGet(
                jwt,
                setIsProfileLoading,
                setIsError,
                setLeagueParticipationMembershipStatusMicroViewModels,
                setRegionMicroViewModels,
                setUniversityMicroViewModels,
                setUserProfileLiteViewModel,
            );
        }
        profileGet();

        return () => {
            //console.log("useEffect empty");
        }
    }, [])

    if(isProfileLoading)
    {
        return (
            <SystemLoadingPage />
        )
    }

    if(isError)
    {
        return (
            <SystemErrorPage tryAgain={async() => await userService.profileGet(
                    jwt,
                    setIsProfileLoading,
                    setIsError,
                    setLeagueParticipationMembershipStatusMicroViewModels,
                    setRegionMicroViewModels,
                    setUniversityMicroViewModels,
                    setUserProfileLiteViewModel
                )
            } />
        )
    }

    let leagueParticipationMembershipStatusOptions = leagueParticipationMembershipStatusMicroViewModels.map((leagueParticipationMembershipStatusMicroViewModel, index) => {
        return <option 
        key={index} 
        value={leagueParticipationMembershipStatusMicroViewModel.id}>
            {leagueParticipationMembershipStatusMicroViewModel.name}
        </option>
    })
    let regionMicroOptions = regionMicroViewModels.map((regionMicroViewModel, index) => {
        return <option key={index} value={regionMicroViewModel.id}>{regionMicroViewModel.name}</option>
    });
    let universityMicroOptions = universityMicroViewModels.map((universityMicroViewModel, index) => {
        return <option key={index} value={universityMicroViewModel.id}>{universityMicroViewModel.name}</option>
    });

    
    let contentForExpert = "";
    let contentForAdvertAboutAuditorium = "";
    if(userProfileLiteViewModel.is_expert !== null && typeof(userProfileLiteViewModel.is_expert) !== "undefined")
    {
        if(userProfileLiteViewModel.is_expert )
        {
            contentForExpert = <div className="alert alert-warning" role="alert">
                <p>
                    Уважаемый эксперт, ожидаем вас на странице <Link to="/expert" >эксперта</Link>
                    <br />
                    для распредления заявок.
                </p>
            </div>
           /*
            contentForAdvertAboutAuditorium = <div className="alert alert-warning" role="alert">
                <p>
                    Уважаемый эксперт. Ждём вас на вебинар 14 июня в 12:00 <br />
                    <Link to="/online_auditorium" >по этой ссылке</Link>.
                </p>
            </div>
            */
        }
    }
    

    return (         

        <form className="profile">
            {contentForExpert}
            {contentForAdvertAboutAuditorium}
            <div className="form-group">
                <label>Электронная почта*</label>
                <input type="email" name="username" className="form-control" defaultValue={userProfileLiteViewModel.username} onChange={formListener} />
            </div>
            <div className="form-group">
                <label>Фамилия*</label>
                <input type="text" name="secondname" className="form-control" defaultValue={userProfileLiteViewModel.secondname} onChange={formListener} />
            </div>
            <div className="form-group">
                <label>Имя*</label>
                <input type="text" name="firstname" className="form-control" defaultValue={userProfileLiteViewModel.firstname} onChange={formListener} />
            </div>
            <div className="form-group">
                <label>Отчество* <span>(напишите "Не имею", если отчество отсутствует)</span></label>
                <input type="text" name="patronymic" className="form-control" defaultValue={userProfileLiteViewModel.patronymic} onChange={formListener} />
            </div>
            <div className="form-group">
                <label>Пол*</label>
                <select name="gender" className="form-control" defaultValue={userProfileLiteViewModel.gender} onChange={formListener}>
                    <option value={0}>- не выбрано</option>
                    <option value={1}>Женский</option>
                    <option value={2}>Мужской</option>
                </select>
            </div>

            <div className="form-group">
                <label>Дата рождения*</label>
                <input type="date" name="birthday" className="form-control" defaultValue={userProfileLiteViewModel.birthday} onChange={formListener} />
            </div>
            <div className="form-group">
                <label>Телефон*</label>
                <input type="text" name="telephone" className="form-control" defaultValue={userProfileLiteViewModel.telephone} onChange={formListener} />
            </div>
            <div className="form-group">
                <label>Страна*</label>
                <input type="text" name="country" className="form-control" defaultValue="Россия" disabled="disabled" />
            </div>
            <div className="form-group">
                <label>Регион*</label>
                <select name="region_id" className="form-control" defaultValue={userProfileLiteViewModel.region_id} onChange={formListener} >
                    <option value={0}>- не выбрано</option>
                    {regionMicroOptions}
                </select>
            </div>

            <div className="form-group">
                <label>Адрес*</label>
                <input type="text" name="address" className="form-control" defaultValue={userProfileLiteViewModel.address} onChange={formListener} />
            </div>

            <div className="form-group">
                <label>Место работы в вузе*</label>
                <select name="university_id" className="form-control" defaultValue={userProfileLiteViewModel.university_id} onChange={formListener} >
                    <option value={0}>- не выбрано</option>
                    {universityMicroOptions}
                </select>
            </div>

            <div className="form-group">
                <label>Должность*</label>
                <input type="text" name="job_title" className="form-control" defaultValue={userProfileLiteViewModel.job_title} onChange={formListener} />
            </div>

            <div className="form-group">
                <label>Подразделение (институт, факультет, кафедра, департамент, отдел и проч.)*</label>
                <input type="text" name="department" className="form-control" defaultValue={userProfileLiteViewModel.department} onChange={formListener} />
            </div>

            <div className="form-group">
                <label>Ссылка на информацию обо мне на сайте вуза*</label>
                <input type="link" name="link_to_information_about_me_on_university_website" className="form-control" defaultValue={userProfileLiteViewModel.link_to_information_about_me_on_university_website} onChange={formListener} />
            </div>

            <div className="form-group">
                <label>Место работы вне вуза*</label>
                <input type="text" name="position_not_university" className="form-control" defaultValue={userProfileLiteViewModel.position_not_university} onChange={formListener} />
            </div>

            <div className="form-group">
                <label>Участие в Лиге Преподавателей Высшей Школы*</label>
                <select name="league_participation_is_membership" className="form-control" defaultValue={userProfileLiteViewModel.league_participation_is_membership} onChange={formListener}>
                    <option value={0}>- не выбрано</option>
                    <option value={1}>Не являюсь членом Лиги Преподавателей Высшей Школы</option>
                    <option value={2}>Являюсь членом Лиги Преподавателей Высшей Школы</option>
                </select>
            </div>
            <div className="form-group">
                <label>Статус участия в конкурсе*</label>
                <select name="league_participation_membership_status_id" className="form-control" defaultValue={userProfileLiteViewModel.league_participation_membership_status_id} onChange={formListener}>
                    <option value={0}>- не выбрано</option>

                    {leagueParticipationMembershipStatusOptions}

                </select>
            </div>

            <hr />

            <div className="form-group">
                <label>Новый пароль</label>
                <input type="password" name="password_new" className="form-control" onChange={formListener} />
            </div>

            <div className="form-group">
                <label>Текущий пароль</label>
                <input type="password" name="password_current" className="form-control" onChange={formListener} />
            </div>

            <hr />

            <div className="control">
                <button type="button" className="btn" disabled={isSaving} onClick={async() => 
                    await userService.profileUpdate(
                        jwt,
                        setIsSaving,
                        setStatusOfUpdate,
                        userProfileLiteViewModel
                        /*
                        userProfileLiteViewModel.username,
                        userProfileLiteViewModel.secondname,
                        userProfileLiteViewModel.firstname,
                        userProfileLiteViewModel.patronymic,
                        userProfileLiteViewModel.password_new,
                        userProfileLiteViewModel.password_current
                        */
                    )
                }>Сохранить</button>
                <p className="result">{statusOfUpdate}</p>
            </div>


        </form>
    )
}