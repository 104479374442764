import { Component } from "react";
import { NavLink, Outlet, useNavigate, useParams } from "react-router-dom";
import UserContext from "../../store/UserContext";
//import imgSocialFacebook from "../../assets/images/social-facebook.png";
//import imgSocialInstagram from "../../assets/images/social-instagram.png";
//import imgSocialYoutube from "../../assets/images/social-youtube.png";
import imgSocialTelegram from "../../assets/images/social-telegram.png";
import imgSocialVK from "../../assets/images/social-vk.png";
import imgSocialBot from "../../assets/images/social-bot.png";
import imgLigaBW from "../../assets/images/liga-bw.png";
import imgLiga from "../../assets/images/liga.png";
import imgGoldenNames from "../../assets/images/golden-names.png";
//import userDefault from "../../assets/images/user-default.jpg";
import UserMiddleware from "../../utils/UserMiddleware";

class MainLayoutClass extends Component 
{
    static contextType = UserContext;

    constructor(props)
    {
        super(props);
        this.actionLogout = this.actionLogout.bind(this);
        /*
        if(typeof(props.params) !== "undefined"){
            console.log("MainLayoutClass props.params.id: " + props.params.id);
        } else {
            console.log("не сработало");
        }
        */
    }

    actionLogout()
    {
        const userMiddleware = new UserMiddleware();
        userMiddleware.clearJWTCookie();
        const { logout } = this.context;
        logout();
        this.props.navigate("/login");
    }

    changeTitle(value)
    {
        console.log("MainLayout changeTitle: " + value);
    }


    render()
    {

        let tabTitle = "Лига преподавателей";
        let pageTitle = "Профиль";
        if((window.location.pathname).indexOf("/contests") + 1)
        {
            pageTitle = "Конкурсы";
        }
        document.title = pageTitle + " | " + tabTitle;
        if((window.location.pathname).indexOf("/online_auditorium") + 1)
        {
            pageTitle = "Онлайн-аудитория";
        }
        if((window.location.pathname).indexOf("/expert") + 1)
        {
            pageTitle = "Эксперт";
        }
        document.title = pageTitle + " | " + tabTitle;
        if((window.location.pathname).indexOf("/expert/user_statement_grade/") + 1)
        {
            pageTitle = "Экспертиза заявления";
        }
        document.title = pageTitle + " | " + tabTitle;
        /*
        if((window.location.pathname).indexOf("/profile") + 1){title = "Профиль";}
        if((window.location.pathname).indexOf("/users") + 1){title = "База клиентов";}
        if((window.location.pathname).indexOf("/lessons") + 1){title = "Занятия";}
        if((window.location.pathname).indexOf("/teacher_salaries") + 1){title = "Зарплаты";}
        if((window.location.pathname).indexOf("/abonements") + 1){title = "Абонементы";}
        if((window.location.pathname).indexOf("/teachers") + 1){title = "Преподаватели";}
        if((window.location.pathname).indexOf("/branches") + 1){title = "Филиалы";}
        if((window.location.pathname).indexOf("/dance_groups") + 1){title = "Общее расписание";}
        if((window.location.pathname).indexOf("/admins") + 1){title = "Администраторы";}
        if((window.location.pathname).indexOf("/invoice") + 1){title = "Касса";}
        if((window.location.pathname).indexOf("/surveillances") + 1){title = "Отслеживание операций";}
        if((window.location.pathname).indexOf("/analytics/purchase_abonements") + 1){title = "История покупок";}
        document.title = title + " | 13 Dance Studio";
        //document.title = title + " | 8 Count 8";
        */

        /*
        let liPanelAdmins = "";
        if(parseInt(localStorage.getItem("panel_admins"), 10) === 1){
            liPanelAdmins = <li><NavLink exact="true" activeclassname="active" to="/admins"><i className="fa fa-users"></i> Администраторы</NavLink></li>;
        }
        let liPanelLessons = "";
        if(parseInt(localStorage.getItem("panel_lessons"), 10) === 1){
            liPanelLessons = <li><NavLink exact="true" activeclassname="active" to="/lessons"><i className="fa fa-list"></i> Занятия</NavLink></li>
        }
        let liPanelUsers = "";
        if(parseInt(localStorage.getItem("panel_users"), 10) === 1){
            liPanelUsers = <li><NavLink exact="true" activeclassname="active" to="/users"><i className="fa fa-users"></i> База клиентов</NavLink></li>
        }
        let liPanelDanceGroups = "";
        if(parseInt(localStorage.getItem("panel_dance_groups"), 10) === 1){
            liPanelDanceGroups = <li><NavLink exact="true" activeclassname="active" to="/dance_groups"><i className="fa fa-calendar"></i> Общее расписание</NavLink></li>
        }
        let liPanelTeachers = "";
        if(parseInt(localStorage.getItem("panel_teachers"), 10) === 1){
            liPanelTeachers = <li><NavLink exact="true" activeclassname="active" to="/teachers"><i className="fa fa-users"></i> Преподаватели</NavLink></li>
        }
        let liPanelTeacherSalaries = "";
        if(parseInt(localStorage.getItem("panel_teacher_salaries"), 10) === 1){
            liPanelTeacherSalaries = <li><NavLink exact="true" activeclassname="active" to="/teacher_salaries"><i className="fa fa-ruble"></i> Зарплаты</NavLink></li>
        }
        let liPanelAbonements = "";
        if(parseInt(localStorage.getItem("panel_abonements"), 10) === 1){
            liPanelAbonements = <li><NavLink exact="true" activeclassname="active" to="/abonements"><i className="fa fa-shopping-cart"></i> Абонементы</NavLink></li>
        }
        let liPanelBranches = "";
        if(parseInt(localStorage.getItem("panel_branches"), 10) === 1){
            liPanelBranches = <li><NavLink exact="true" activeclassname="active" to="/branches"><i className="fa fa-home"></i> Филиалы</NavLink></li>
        }
        let liPanelCashbox = "";
        if(parseInt(localStorage.getItem("panel_cashbox"), 10) === 1){
            liPanelCashbox = <li><NavLink exact="true" activeclassname="active" to="/cashbox"><i className="fa fa-briefcase"></i> Касса</NavLink></li>
        }
        let liPanelSurveillances = "";
        if(parseInt(localStorage.getItem("panel_surveillance"), 10) === 1){
            liPanelSurveillances =  <li><NavLink exact="true" activeclassname="active" to="/surveillances"><i className="fa fa-eye"></i> Слежка</NavLink></li>
        }
                            <p className="firstname">{localStorage.getItem("firstname")}</p>


                            
                                <p className="fio">Фамилия Имя Отчество</p>
                                <img src={userDefault} className="img-fluid avatar" alt="avatar" />
                                <div className="avatar-control">
                                    <p>Обновить фотографию</p>
                                    <Button variant="default">Сохранить</Button>
                                </div>

                                
                <div className="announcement">
                    <p><span>Уважаемые пользователи!</span><br />
                    Почта поддержки support@professorstoday.org больше не работает!<br />
                    Все ваши обращения по поводу неполадок работы сайта или вопросы, касающиеся заполнения заявки, убедительная просьба присылать на почту <a href="mailto:info@professorstoday.org">info@professorstoday.org</a>
                    </p>
                </div>
        */

        return (
            <>
                <div className="header">
                    <div className="top">
                        <h1>Цифровая экосистема</h1>
                    </div>
                    <div className="middle">
                        <h1>Лиги преподавателей высшей школы</h1>
                    </div>
                    <div className="bottom"></div>
                </div>

                
                <div className="container main">
                    <div className="row">
                        <div className="col-12 col-lg-3 col-md-4 col-sm-4">
                            <div className="block-bordered menu">
                                
                                <ul>
                                    <li><NavLink exact="true" activeclassname="active" to="/">Профиль</NavLink></li>
                                    <li><NavLink exact="true" activeclassname="active" to="/contests">Конкурсы</NavLink></li>
                                    <li className="logout"><NavLink to="#" onClick={() => this.actionLogout()}>Выйти</NavLink></li>
                                    
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-9 col-md-8 col-sm-8">
                            <div className="block-bordered page-title">
                                <div className="row">
                                    <div className="col-3">
                                        <NavLink to="https://professorstoday.org" target="_blank" alt="professorstoday.org">
                                            <img src={imgLiga} className="img-fluid" alt="Лига" />
                                        </NavLink>
                                    </div>
                                    <div className="col-6">
                                        <h1>{pageTitle}</h1>
                                    </div>
                                    <div className="col-3 text-right">
                                        <NavLink to="https://goldennames.professorstoday.org" target="_blank" alt="goldennames.professorstoday.org">
                                            <img src={imgGoldenNames} className="img-fluid" alt="Золотые имена" />
                                        </NavLink>
                                    </div>
                                </div>
                            </div>

                            <div className="block-bordered">
                                <Outlet changeTitle={this.changeTitle} />
                            </div>
                        </div>
                    </div>
                </div>


                <footer>
                    <div className="top"></div>
                    <div className="pretop">
                        <p>Мы в социальных сетях</p>
                    </div>
                    <div className="middle">
                        <NavLink to="https://t.me/goldzivsh" target="_blank">
                            <img src={imgSocialTelegram} className="img-fluid" alt="social-telegram" />
                        </NavLink>
                        <NavLink to="https://vk.com/professorsonline" target="_blank">
                            <img src={imgSocialVK} className="img-fluid" alt="social-vkontakte" />
                        </NavLink>
                    <NavLink to="https://t.me/goldzivsh_support_bot" target="_blank">
                        <img src={imgSocialBot} className="img-fluid" alt="social-bot" />
                    </NavLink>
                    </div>

                    <div className="bottom">
                        <div className="row">
                            <div className="col-3 d-none d-md-block"></div>
                            <div className="col-12 col-lg-4 col-md-4 col-sm-12">
                                <img src={imgLigaBW} className="img-fluid" alt="liga-bw" />
                                <p>Межрегиональная общественная организация<br />"Лига Преподавателей Высшей Школы"</p>
                                <NavLink to="/docs/Политика в отношении обработки персональных данных.pdf" exact="true" target="_blank">Политика обработки персональных данных</NavLink>
                            </div>
                            <div className="col-12 col-lg-4 col-md-4 col-sm-12 contacts">
                                <h6>Контакты</h6>
                                <p>+7 (499) 444-52-04</p>
                                <p>goldnames@professorstoday.org</p>
                                <p>101000, г. Москва, ул. Покровка, дом 14/2, стр. 1</p>
                            </div>
                            <div className="col-12 copyright">
                                <p>© 2023, Лига Преподавателей Высшей Школы. Все права защищены</p>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        )
    }
}

export default function MainLayout(props){
    const navigate = useNavigate();
    return(<MainLayoutClass {...props} navigate={navigate} params={useParams()}></MainLayoutClass>)
};